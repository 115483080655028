import axios from "axios";
import scoreEvent from "./ScoreEvent";

const getAPIURL = () => {
  let t = new Date().getTime() + "";
  // Get the current timestamp in milliseconds
  t = t.substring(5);
  return `https://fxverdict.com:3000?t=${t}`;
};

// Replace with your API URL


export const DataService = {
  getNews() {
    return axios.get(getAPIURL()).then((response) => response.data.calendar);
  },

  getUpcomingNews() {
    return axios.get(getAPIURL()).then((response) => {
      const currentTime = Math.floor(Date.now() / 1000); // Convert to seconds
      let events = response.data.calendar;

      // filters all news beyond this second
      let evs = events.filter((e) => e.dateline >= currentTime);
      if (evs.length > 10) {
        evs = evs.slice(0, 10);
      }
      return evs;
    });
  },

  getPairsInAction() {
    return axios.get(getAPIURL()).then((response) => {
      const timestampInSeconds = Math.floor(Date.now() / 1000);
      const evs = response.data.calendar.filter((e) => e.dateline <= timestampInSeconds);

      const currencyMap = this.prepareCurrencyMap(evs);
      const scoreMatrix = this.analyseCurrencies(currencyMap);
      return {"scoreMatrix":this.analyzeMatrix(scoreMatrix),"lastUpdated":response.data.lastUpdated};
    });
  },

  getPairsInActionForDay() {
    return axios.get(getAPIURL()).then((response) => {
      const timestampInSeconds = Math.floor(Date.now() / 1000);
      const last24hrNews = timestampInSeconds - 24 * 3600;
      const evs = response.data.calendar.filter(
        (e) => e.dateline >= last24hrNews && e.dateline <= timestampInSeconds
      );

      const currencyMap = this.prepareCurrencyMap(evs);
      const scoreMatrix = this.analyseCurrencies(currencyMap);
      return {"scoreMatrix":this.analyzeMatrix(scoreMatrix),"lastUpdated":response.data.lastUpdated};
    });
  },

  prepareCurrencyMap(events) {
    const currenciesInNews = {};

    events.forEach((event) => {
      const currency = event.currency;
      if (!currenciesInNews[currency]) {
        currenciesInNews[currency] = [event];
      } else {
        currenciesInNews[currency].push(event);
      }
    });

    return currenciesInNews;
  },

  analyseCurrencies(currenciesInNews) {
    const scoreMatrix = [];
    const currencies = Object.keys(currenciesInNews);

    for (const currency of currencies) {
      const timestampInMilliseconds = Date.now();
      const c_events = currenciesInNews[currency];

      const lowImpactCountEvents = c_events.filter(
        (ev) =>
          ev.impactTitle.toLowerCase().includes("low") &&
          ev.dateline < timestampInMilliseconds
      );

      const highImpactCountEvents = c_events.filter(
        (ev) =>
          ev.impactTitle.toLowerCase().includes("high") &&
          ev.dateline < timestampInMilliseconds
      );

      const mediumImpactCountEvents = c_events.filter(
        (ev) =>
          ev.impactTitle.toLowerCase().includes("medium") &&
          ev.dateline < timestampInMilliseconds
      );

      const importantEvents = [
        ...mediumImpactCountEvents,
        ...highImpactCountEvents,
        // ...lowImpactCountEvents,
      ];

      if (importantEvents.length === 0) {
        continue;
      }

      // const scores = importantEvents.map((ev) => ev.aiResponse);
      const scores = importantEvents.map((ev) => {
        const manualScore = scoreEvent(ev);
        // console.log(ev.name);
        // console.log({ ...ev.aiResponse, manualScore: manualScore });
        // return { ...ev.aiResponse, manualScore: manualScore };
        return manualScore;
      });

      scoreMatrix.push({ currency, scores: scores });
    }

    return scoreMatrix;
  },

  analyzeMatrix(mat) {
    // const finalArray = [];

    // for (const obj of mat) {
    //   const score_obj = {
    //     currency: obj.currency,
    //     scores : obj.score
        
    //   };

    //   obj.scores.forEach((aiResp) => {
    //     count +=1
    //       avgScore += aiResp.manualScore;
    //   });

    //   score_obj.avg = avgScore/count

    //   finalArray.push(score_obj);
    // }

    // return finalArray;
    return mat;
    
  },
};
