import React, { useState, useEffect } from 'react'; // Import necessary hooks
import "./currency-pair-list-style.css"
import MajorPairs from '../../data/MajorPairs';

const CurrencyPairListComponent = ({ currencyPairs = [], onCurrencyClicked }) => {
    const [filteredPairs, setFilteredPairs] = useState(currencyPairs); // State for filtered pairs
    const [searchPair, setSearchPair] = useState(''); // State for search term


    useEffect(() => {
        // Sort currencyPairs on initial render
        setFilteredPairs(MajorPairs.sort((a, b) => a.symbolName.localeCompare(b.symbolName)))
        
    }, []);

    const listItemClicked = (selectedPair) => {
        onCurrencyClicked?.(selectedPair); // Optional callback if provided
    };

    const onTextChange = (event) => {
        const searchString = event.target.value.trim().toUpperCase();
        setSearchPair(searchString)
        const filtered = currencyPairs.filter((pair) => pair.symbolName.includes(searchString));
        setFilteredPairs(filtered);
    };

    const popularCurrencyPicked = (event) => {
        const selectedValue = event.target.value;

        if (selectedValue == "MAJOR") {
            setFilteredPairs(MajorPairs)

        } else {
            const filtered = currencyPairs.filter((pair) => pair.symbolName.includes(selectedValue));
            setFilteredPairs(filtered);
        }

    };

    return (
        <div id="currency-pair-list-component">

            <div className="list_container">
                <input
                    type="text"
                    value={searchPair}
                    onChange={onTextChange}
                    placeholder="Search Pairs"
                />

                <select className="top_pairs_list" onChange={popularCurrencyPicked}>
                    <option value="MAJOR">Major Pairs</option>
                    <option value="USD">USD</option>
                    <option value="AUD">AUD</option>
                    <option value="CAD">CAD</option>
                    <option value="CHF">CHF</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="JPY">JPY</option>
                    <option value="NZD">NZD</option>
                    <option value="">All Pairs</option>
                </select>

                <ul>
                    {filteredPairs.map((pair) => (
                        <li
                            key={pair.symbolName}
                            onClick={() => listItemClicked(pair)}
                            className="currency_pair_list_item noselect"
                        >
                            <span className="title">{pair.symbolName}</span>
                            {/* Comment out or conditionally render the options panel if needed */}
                            {/* <div className="options_panel">
                <button className="op_button">Add</button>
                <button className="op_button">Show</button>
              </div> */}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};


export default CurrencyPairListComponent;


