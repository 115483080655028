const importantEventScoring = (
  actual,
  previous,
  forecast,
  actualGreaterThanPreviousIsGood = true
) => {
  
  
    // +2 for good news
  // -2 for bad news
  //  +3 for important event positive
  // -3 for important event negative
  // 0 for neutral
  // -4 for excessively bad news for non-important events
  // +4 for excessively good news for non-important events
  // -5 for excessively bad news for important events
  // +5 for excessively good news for important events

  
    if (actualGreaterThanPreviousIsGood) {
    if (actual > previous) {
      return 3;
    } else if (actual == previous) {
      return 0;
    } else if (forecast > previous && actual < previous) {
      // excessively bad
      return -5;
    } else if (forecast < previous && actual > previous) {
      // excessively good
      return 5;
    } else {
      return -3;
      // bad
    }
  } else {
    // 'Actual' less than 'Previous' is good for currency;
    if (actual < previous) {
      return 3;
    } else if (actual == previous) {
      return 0;
    } else if (forecast < previous && actual > previous) {
      // excessively bad
      return -5;
    } else if (forecast > previous && actual < previous) {
      // excessively good
      return 5;
    } else {
      return -3;
      // bad
    }
  }

  
};

const nonImportantEventScoring = (
  actual,
  previous,
  forecast,
  actualGreaterThanPreviousIsGood = true
) => {
  if (actualGreaterThanPreviousIsGood) {
    if (actual > previous) {
      return 2;
    } else if (actual == previous) {
      return 0;
    } else if (forecast > previous && actual < previous) {
      // excessively bad
      return -4;
    } else if (forecast < previous && actual > previous) {
      // excessively good
      return 4;
    } else {
      return -2;
      // bad
    }
  } else {
    // 'Actual' less than 'Previous' is good for currency;
    if (actual < previous) {
      return 2;
    } else if (actual == previous) {
      return 0;
    } else if (forecast < previous && actual > previous) {
      // excessively bad
      return -4;
    } else if (forecast > previous && actual < previous) {
      // excessively good
      return 4;
    } else {
      return -2;
      // bad
    }
  }
};

const scoreEvent = (ev) => {

  // removing non-alpha numeric chars except decimal or plus minus
  const actual = ev.actual.replace(/[^0-9.+-]/g, "");
  const previous = ev.previous.replace(/[^0-9.+-]/g, "");
  const forecast = ev.forecast.replace(/[^0-9.+-]/g, "");
  const eventType = ev.name.toLowerCase();

  if (eventType.includes("business confidence")) {
    // 'Actual' greater than 'Previous' is good for currency;
    return nonImportantEventScoring(actual, previous, forecast);
  }

  if (eventType.includes("pmi") || eventType.includes("manufacturing prices")) {
    // 'Actual' greater than 'Previous' is good for currency;
    return nonImportantEventScoring(actual, previous, forecast);
  }

  if (eventType.includes("cpi")) {
    // important event
    // 'Actual' greater than 'Previous' is good for currency;
    return importantEventScoring(actual, previous, forecast);
  }

  if (eventType.includes("retail sales")) {
    // 'Actual' greater than 'Previous' is good for currency;
    return nonImportantEventScoring(actual, previous, forecast);
  }

  if (eventType.includes("job openings")) {
    // 'Actual' greater than 'Previous' is good for currency;
    return importantEventScoring(actual, previous, forecast);
  }

  if (eventType.includes("non-farm employment change")) {
    // 'Actual' greater than 'Previous' is good for currency;
    return importantEventScoring(actual, previous, forecast);
  }

  if (eventType.includes("crude oil inventories")) {
    // 'Actual' less than 'Previous' is good for currency;
    return importantEventScoring(actual, previous, forecast, false);
  }

  if (eventType.includes("unemployment")) {
    // 'Actual' less than 'Previous' is good for currency;
    return importantEventScoring(actual, previous, forecast, false);
  }

  if (eventType.includes("average hourly earnings")) {
    // 'Actual' greater than 'Previous' is good for currency;
    return importantEventScoring(actual, previous, forecast);
  }

  if (eventType.includes("cash rate")) {
    // 'Actual' greater than 'Previous' is good for currency;
    return importantEventScoring(actual, previous, forecast);
  }

  if (eventType.includes("gdp")) {
    // 'Actual' greater than 'Previous' is good for currency;
    return importantEventScoring(actual, previous, forecast);
  }

  if (eventType.includes("employment change")) {
    // 'Actual' greater than 'Previous' is good for currency;
    return importantEventScoring(actual, previous, forecast);
  }

  if (eventType.includes("consumer sentiment")) {
    // 'Actual' greater than 'Previous' is good for currency;
    return nonImportantEventScoring(actual,previous,forecast)
  }

  if (eventType.includes("inflation")) {
    // 'Actual' greater than 'Previous' is good for currency;
    return nonImportantEventScoring(actual,previous,forecast)
  }

  if (eventType.includes("ppi")) {
    // 'Actual' greater than 'Previous' is good for currency;
    return nonImportantEventScoring(actual,previous,forecast)
  }

  if (eventType.includes("building permits")) {
    // 'Actual' greater than 'Previous' is good for currency;
    return nonImportantEventScoring(actual,previous,forecast)
  }

  return 0;
};

export default scoreEvent;
