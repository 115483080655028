import { currency_pairs } from "./CurrencyPairs";


const cmap = {};

let major_pairs = ['USDCAD','EURCAD','GBPCAD','CADCHF','AUDCAD','NZDCAD','EURUSD','USDJPY','GBPUSD','USDCHF','NZDUSD','EURNZD','EURCHF',
    'EURAUD','EURGBP','GBPNZD','GBPCHF','GBPAUD','NZDCHF','AUDNZD','AUDCHF','AUDUSD','CADJPY','EURJPY','GBPJPY','CHFJPY','AUDJPY'
]

major_pairs.forEach(item=>{
    cmap[item] = 1;
})

let MajorPairs;

MajorPairs = currency_pairs.filter(item=>{
    return cmap[item.symbolName]==1;
})

export default MajorPairs;
