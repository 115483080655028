import React, { useEffect, useState } from 'react';
import CurrencyPairListComponent from '../currency-pair-list/CurrencyPairList';
import SelectedPairInfoComponent from '../selected-pair-info/SelectedPairInfoComponent';
import PairsInNewsComponent from '../pair-in-news/PairInNewsComponent';
import { currency_pairs } from '../../data/CurrencyPairs';
import "./home-style.css"
import { DataService } from '../../service/DataService';
import ForexSessionClockComponent from '../forex-session-clock/ForexSessionClock';



function HomeComponent() {
  const [title] = useState('strength-meter-react');
  const [showSelectedPairInfoComponent, setShowSelectedPairInfoComponent] = useState(false);
  const [selectedPair, setSelectedPair] = useState(null);
  const [allNewsItems, setAllNewsItems] = useState([])



  useEffect(() => {
    const promise = DataService.getNews();


    promise.then((newsItems) => setAllNewsItems(newsItems))

  }, [])

  const currencyPairClicked = (selectedPair) => {
    setSelectedPair(selectedPair);
    // alert(selectedPair.symbolName)

    setShowSelectedPairInfoComponent(true);
  };

  const hidePairInfoComponent = () => {
    setShowSelectedPairInfoComponent(false);
  };

  return (
    <div id="home-component" className="wrapper">
      <div className="currency-pair-list">
        <CurrencyPairListComponent

          onCurrencyClicked={currencyPairClicked}
          currencyPairs={currency_pairs}

        />
      </div>


      <div className="info-container">


        <div className="header">

          <h2 className='homeButton'><a href="/">FxVerdict</a></h2>

          {!showSelectedPairInfoComponent && <ForexSessionClockComponent></ForexSessionClockComponent>}
          {showSelectedPairInfoComponent && (
            <span className="closeButton" onClick={hidePairInfoComponent}>X</span>
          )}
        </div>
        {selectedPair && showSelectedPairInfoComponent && (
          <div
            className="selected-pair-info"

          >

            <SelectedPairInfoComponent

              selectedPair={selectedPair}
              allNewsItems={allNewsItems}
            />

          </div>

        )}

        {!showSelectedPairInfoComponent && (
          <PairsInNewsComponent className="trade-pair-suggestions" />
        )}

      </div>
    </div>
  );
}

export default HomeComponent;