import React, { useEffect, useState } from 'react';
import "./forex-session-clock-style.css"


const ForexSessionClockComponent = () => {

    const [sessionDetails, setSessionDetails] = useState([]);





    useEffect(() => {
        updateSessionDetails()


        let sessionId = setInterval(() => {
            updateSessionDetails();
        }, 60 * 1000); // Update every 10 seconds



        // return () => {
        //     clearInterval(sessionId);
        // }

    }, []);


    const getCurrentSession = () => {
        const sessions = [
            { name: 'SYDNEY', open: 22, close: 7, dst: true },   // Sydney observes DST
            { name: 'TOKYO', open: 0, close: 9, dst: false },    // Tokyo does not observe DST
            { name: 'LONDON', open: 8, close: 17, dst: true },   // London observes DST
            { name: 'NEWYORK', open: 13, close: 22, dst: true }  // New York observes DST
        ];
    
        const now = new Date();
        const currentHour = now.getUTCHours();
        const currentDay = now.getUTCDay();
    
        // Check if DST is active in relevant regions
        const isDST = (region) => {
            const year = now.getFullYear();
            switch (region) {
                case 'SYDNEY':
                    // DST starts first Sunday in October, ends first Sunday in April
                    return (now >= new Date(`October 1, ${year}`).setDate(1 + (7 - new Date(`October 1, ${year}`).getDay()) % 7) &&
                            now < new Date(`April 1, ${year}`).setDate(1 + (7 - new Date(`April 1, ${year}`).getDay()) % 7));
                case 'LONDON':
                    // DST starts last Sunday in March, ends last Sunday in October
                    return (now >= new Date(`March 1, ${year}`).setDate(31 - (new Date(`March 31, ${year}`).getDay())) &&
                            now < new Date(`October 1, ${year}`).setDate(31 - (new Date(`October 31, ${year}`).getDay())));
                case 'NEWYORK':
                    // DST starts second Sunday in March, ends first Sunday in November
                    return (now >= new Date(`March 1, ${year}`).setDate(14 - new Date(`March 14, ${year}`).getDay()) &&
                            now < new Date(`November 1, ${year}`).setDate(7 - new Date(`November 7, ${year}`).getDay()));
                default:
                    return false; // Tokyo doesn't observe DST
            }
        };
    
        // Adjust session hours for DST if applicable
        const adjustedSessions = sessions.map(session => {
            if (session.dst && isDST(session.name)) {
                return {
                    ...session,
                    open: (session.open + 1) % 24,  // Shift 1 hour forward
                    close: (session.close + 1) % 24
                };
            }
            return session;
        });
    
        // Forex market is closed on Saturday (6) and Sunday (0)
        if (currentDay === 6 || currentDay === 0) {
            return [];
        }
    
        return adjustedSessions
            .filter(session => (session.open <= currentHour && currentHour < session.close) ||
                (session.open > session.close && (currentHour >= session.open || currentHour < session.close)))
            .map(session => session.name);
    };
    
    
    const updateSessionDetails = () => {
        const openSessions = getCurrentSession();
        const updatedSessionDetails = [
            {
                name: 'Sydney',
                image: 'assets/forex-clock/sydney.png',
                status: openSessions.includes('SYDNEY') ? 'open' : 'closed',
                statusText: openSessions.includes('SYDNEY') ? 'Open' : 'Closed'
            },
            {
                name: 'Tokyo',
                image: 'assets/forex-clock/tokyo.png',
                status: openSessions.includes('TOKYO') ? 'open' : 'closed',
                statusText: openSessions.includes('TOKYO') ? 'Open' : 'Closed'
            },
            {
                name: 'London',
                image: 'assets/forex-clock/london.png',
                status: openSessions.includes('LONDON') ? 'open' : 'closed',
                statusText: openSessions.includes('LONDON') ? 'Open' : 'Closed'
            },
            {
                name: 'New York',
                image: 'assets/forex-clock/newyork.png',
                status: openSessions.includes('NEWYORK') ? 'open' : 'closed',
                statusText: openSessions.includes('NEWYORK') ? 'Open' : 'Closed'
            }
        ];

        setSessionDetails(updatedSessionDetails);
    };

    return (
        <div id="forex-session-clock-component">
            <div className="forex-session-container">
                <div className="sessions">

                    {/* <Clock /> */}
                    {/* <div className="clock">
                        <h2>
                            Current Time <br />
                            {new Date(currentTime).toLocaleTimeString()} UTC
                        </h2>
                    </div> */}
                    {sessionDetails.map((session, index) => (
                        <div className="session" key={index}>
                            <div className="session-image">
                                <img
                                    src={session.image}
                                    title={session.name}
                                    alt={`${session.name} session`}
                                />
                            </div>
                            <p className='session-name'>{session.name}</p>
                            <div className="session-info">
                                <span className="status">
                                    {session.statusText === 'Open' ? (
                                        <div className="green-dot" title="Session open"></div>
                                    ) : (
                                        <div className="red-dot" title="Session closed"></div>
                                    )}{' '}
                                    {/* {session.statusText} */}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ForexSessionClockComponent;