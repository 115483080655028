import React from 'react';
import "./pair-strength-info-header-style.css"
const PairStrengthInfoHeaderComponent = ({ symbolName, impact, newsCount }) => {
    return (
        <div id="pair-strength-info-header-component">
        <div  className="container">
            <div className="tab_layout">
                <div className="tab">
                    <h2 className="pairName">{symbolName}</h2>
                </div>

                <div className="tab">
                    <h3 className="tabl_title">News Events</h3>
                    <h4>{newsCount}</h4>
                </div>

                <div className="tab">
                    <h3 className="tabl_title low_impact">Low Impact</h3>
                    <h4>{impact.lowImpactCount}</h4>
                </div>

                <div className="tab">
                    <h3 className="tabl_title medium_impact">Medium Impact</h3>
                    <h4>{impact.mediumImpactCount}</h4>
                </div>

                <div className="tab">
                    <h3 className="tabl_title high_impact">High Impact</h3>
                    <h4>{impact.highImpactCount}</h4>
                </div>

                {/* <div className="tab">
              <h3 className="tabl_title">Directional Bias</h3>
              <h4 className="green">Strongly Positive</h4>
            </div> */}
            </div>
        </div>
        </div>
    );
};

export default PairStrengthInfoHeaderComponent;