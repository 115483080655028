import React, { useState, useEffect } from 'react';
import { currency_pairs } from '../../data/CurrencyPairs';
import "./lot-size-calculator-style.css"
const LotSizeCalculatorComponent = ({ selectedPair }) => {

    const [accountCurrencyCode, setAccountCurrencyCode] = useState('USD');
    const [riskInAccountCurrency, setRiskInAccountCurrency] = useState(100);
    const [lotsize, setLotsize] = useState(100000);
    const [pipSize, setPipSize] = useState(0);
    const [pipValue, setPipValue] = useState(0);
    
    const [stoplossInPips, setStoplossInPips] = useState(10);
    const [numberOfLots, setNumberOfLots] = useState(0);

    useEffect(() => {
        computeLots();
    }, [selectedPair, accountCurrencyCode, riskInAccountCurrency, lotsize, stoplossInPips]);

    const computeLots = () => {
        if (!selectedPair) {
            return;
        }

        const pipS = selectedPair.pipsize
        setPipSize(pipS);
        const pipV = lotsize * pipS
        setPipValue(pipV);
        var riskInQuotedCurrency = calculateRiskInQuotedCurrency();

        if (!stoplossInPips) {
            setNumberOfLots(0);
            return;
        }

        setNumberOfLots(
            Math.round(
                (riskInQuotedCurrency / (stoplossInPips * pipV) + Number.EPSILON) * 100
            ) / 100
        );
    };

    const calculateRiskInQuotedCurrency = () => {
        if (!selectedPair) {
            return 0;
        }

        // alert(JSON.stringify(selectedPair))
        const quotedCurrencyCode = selectedPair.symbolName.substring(3);
        //CAD for AUDCAD

        if (quotedCurrencyCode !== accountCurrencyCode) {
            const pairName = quotedCurrencyCode + accountCurrencyCode;
            const c = currency_pairs.filter((cp) => cp.symbolName === pairName);

            if (c.length > 0) {
                const c_rate = Number(c[0].symbolRate);
                return riskInAccountCurrency / c_rate;
            }

            const pairName2 = accountCurrencyCode + quotedCurrencyCode;
            const c2 = currency_pairs.filter((cp) => cp.symbolName === pairName2);

            if (c2.length > 0) {
                const c_rate = Number(c2[0].symbolRate);
                return riskInAccountCurrency * c_rate;
            }

            return 0;
        } else {
            return riskInAccountCurrency;
        }
    };

    return (
        <div id="lot-size-calculator-component">
        <div className="container" >
            <h3 className="title">Lot size calculator</h3>
            <div className="form_layout">
                <div>
                    <p>Stop loss in pips</p>
                    <input
                        type="number"
                        value={stoplossInPips}
                        onChange={(e) => {
                            setStoplossInPips(e.target.value);
                            computeLots()
                        }}
                        placeholder=""
                    />
                </div>

                <div>
                    <p>Risk Amount</p>
                    <input
                        type="number"
                        value={riskInAccountCurrency}
                        onChange={(e) => {
                            setRiskInAccountCurrency(e.target.value);
                            computeLots()

                        }
                        }
                        placeholder=""
                    />
                </div>

                <div>
                    <p>Account Currency</p>
                    <select
                        id="cars"
                        value={accountCurrencyCode}
                        onChange={(e) => {
                            setAccountCurrencyCode(e.target.value);
                            computeLots()
                        }
                        }
                    >
                        <option value="USD">USD</option>
                        <option value="AUD">AUD</option>
                        <option value="CAD">CAD</option>
                        <option value="CHF">CHF</option>
                        <option value="EUR">EUR</option>
                        <option value="GBP">GBP</option>
                        <option
                            value="JPY">JPY</option>
                        <option value="NZD">NZD</option>
                    </select>
                </div>

                <div>

                    <p>Qty (Standard Lots) </p>
                    <h3 className="title">{numberOfLots}</h3>
                </div>
            </div>
        </div>
        </div>
    );
};

export default LotSizeCalculatorComponent;