import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Assuming an HTTP client like Axios for fetching data
import PairStrengthInfoHeaderComponent from '../pair-strength-info-header/PairStrengthInfoHeaderComponent';
import SelectedPairNewsEventListComponent from '../selected-pair-news-event-list/SelectedPairNewsEventListComponent';
import LotSizeCalculatorComponent from '../lot-size-calculator/LotSizeCalculatorComponent';
import "./selected-pair-info-style.css"
import { DataService } from '../../service/DataService';

const SelectedPairInfoComponent = ({ selectedPair, allNewsItems }) => {

    const [lowImpactCount, setLowImpactCount] = useState(0);
    const [mediumImpactCount, setMediumImpactCount] = useState(0);
    const [highImpactCount, setHighImpactCount] = useState(0);
    const [newsItems, setNewsItems] = useState([]);



    useEffect(() => {



        const selectedCurrencyName = selectedPair.symbolName.toLowerCase();

        const filteredItems = allNewsItems.filter((item) =>
            selectedCurrencyName.includes(item.currency.toLowerCase())
        )


        setNewsItems(
            filteredItems
        );


        setLowImpactCount(0)
        setMediumImpactCount(0)
        setHighImpactCount(0)
        let low = 0
        let medium = 0
        let high = 0

        filteredItems.forEach((item) => {
            // console.log(JSON.stringify(item))
            const impactLabel = item.impactTitle.toLowerCase();
            if (impactLabel.includes('low')) {
                low += 1
            } else if (impactLabel.includes('medium')) {
                medium += 1
            } else if (impactLabel.includes('high')) {
                high += 1
            }

        })

        setLowImpactCount(low)
        setMediumImpactCount(medium)
        setHighImpactCount(high)



    }, [selectedPair]);


    const parseImpact = (item) => {
        // Consider using a switch statement for potentially better readability with more impact categories
        const impactLabel = item.impactTitle.toLowerCase();
        if (impactLabel.includes('low')) {
            setLowImpactCount((prevCount) => prevCount + 1);
        } else if (impactLabel.includes('medium')) {
            setMediumImpactCount((prevCount) => prevCount + 1);
        } else if (impactLabel.includes('high')) {
            setHighImpactCount((prevCount) => prevCount + 1);
        }
    };


    return (
        <div className="container" id="selected-pair-info-component">
            <PairStrengthInfoHeaderComponent
                symbolName={selectedPair.symbolName}
                newsCount={newsItems.length}
                impact={{ lowImpactCount: lowImpactCount, mediumImpactCount: mediumImpactCount, highImpactCount: highImpactCount }}
            />
            <SelectedPairNewsEventListComponent newsItems={newsItems} />
            <LotSizeCalculatorComponent selectedPair={selectedPair} />
        </div>
    );
};

export default SelectedPairInfoComponent;